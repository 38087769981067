import {Organization} from '../_models/Organization';
import {Injectable} from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class CurrentOrgInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const storedData = localStorage.getItem('lido-bo-current-org');
        if (!storedData) return next.handle(req);

        const currentOrg: Organization = JSON.parse(storedData);
        if (!currentOrg) return next.handle(req);

        const modifiedRequest = req.clone({
            headers: req.headers
                .append('CurrentOrgId', currentOrg.id.toString())
                .append('x-selected-store', currentOrg.name.toString())
        });

        return next.handle(modifiedRequest);
    }
}
